export const extractData = (input) => {
  const regex = /^(.+)\[(.+)\]\n(.+)$/;
  const match = input.match(regex);

  if (match && match.length === 4) {
    const name = match[1].trim();
    const text = match[3].trim();
    return {name, text};
  }

  return null;
}

export const extractText = (msg) => {

  msg = msg.replace(/\[USER=(.*?)\](.*?)\[\/USER\]/g, '$2,');

  let extractMessageData = {
    answer: null,
    text: null
  }

  if (msg && msg.includes('------------------------------------------------------')) {

    const regex = /------------------------------------------------------([^------------------------------------------------]+)------------------------------------------------------([^------------------------------------------------]+)/;
    const match = msg.match(regex);

    if (match && match.length > 2) {
      const extractedText = match[1].trim();
      const followingText = match[2].trim();

      extractMessageData = {
        answer: extractData(extractedText),
        text: followingText
      }

    }
  }
  if (extractMessageData.text === null) {
    extractMessageData.text = msg
  }

  return extractMessageData;
}
