import {create} from 'zustand';
import apiClient from "../api/axiosConfig";
import {apiChatGetHistory, apiChatShowMore} from "../api/chatApi";

export const useChatStore = create((set, get) => ({
  chat: [],
  selectedMessageData: null,
  replyMessageId: null,
  chatModalOpen: false,
  fileUploadingStatus: false,
  haveNewMessages: localStorage.getItem('haveNewMessages') || false,

  updateChat: async (messageId) => {
    const messages = [];
    const users = {};

    //const data = messageId ? await apiChatShowMore(messageId) : await apiChatGetHistory();

    let data;
    if (messageId) {
      data = await apiChatShowMore(messageId)
    } else {
      data = await apiChatGetHistory()
    }

    let counter = 0;
    if (data?.status === "success") {
      const chatMessages = data?.data;

      for (let key in chatMessages) {
        counter++;
        const message = chatMessages[key]
        let file =  null
        if(message.FILES && message.FILES.length) {
          file= {
            name:  message.FILES[0].FILE_NAME,
            type: message.FILES[0].CONTENT_TYPE.split('/')[0],
            src: message.FILES[0].SRC
          }
        }
        messages.push({
          "user_id": message.senderId,
          "chat_id": message.chatId,
          "message_id": key,
          "senderStatus": message.author?'clinic':'user',
          "senderName": message.author,
          "text": message.text,
          "date": new Date(message.date),
          "file": file
        });
      }
    }

    //alert(counter)

    // Получаем текущее состояние чата
    const currentChat = get().chat;

    if (messageId) {
      // Если передан messageId, добавляем новые сообщения в конец существующего массива чата
      set({chat: [...messages, ...currentChat]});
    } else {
      // Если messageId не передан, заменяем текущие сообщения новыми
      set({chat: messages});
    }
    if(counter<14 || (Array.isArray(data?.data) && data?.data.length === 0)) {
      return 'end'
    }
  },

  addChatMessage: (data) => set((state) => {
    return ({chat: [...state.chat, data]})
  }),
  updateSelectedMessageData: (data) => set(() => ({selectedMessageData: data})),
  updateReplyMessageId: (data) => set(() => ({replyMessageId: data})),
  updateChatModalOpen: (data) => set(() => ({chatModalOpen: data})),
  updateFileUploadingStatus: (data) => set(() => ({fileUploadingStatus: data})),
  setHaveNewMessages: (haveNewMessages) => {
    localStorage.setItem('haveNewMessages', haveNewMessages)
    return set({ haveNewMessages })
  },
}));
