import React, {useEffect, useLayoutEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import {use100vh} from 'react-div-100vh'
import LayoutBase from "@components/layouts/LayoutBase";
import AuthDispatcher from "@pages/AuthDispatcher";
import PageLogin from "@pages/PageLogin";
import PageNotifications from "@pages/PageNotifications";
import PageCall from "@pages/PageCall";
import PageChat from "@pages/PageChat";
import PageMain from "@pages/PageMain";
import PageMainAuth from "@pages/PageMainAuth";
import PageRegister from "@pages/PageRegister";
import PageSettings from "@pages/PageSettings";
import RequiredAuth from "./components/RequiredAuth";
import {SIPProvider} from "react-sipjs";
import OfflineMessage from "./components/OfflineMessage";
import {useUserStore} from "./store/user-store";
import PageLoginNewPassword from "./pages/PageLoginNewPassword";
import PageLoginAuth from "./pages/PageLoginAuth";
import {deviceType} from "./utils/getDeviceType";
import Test from "./pages/Test";
import {apiChatGetHistory, apiChatServer, apiChatShowMore} from "./api/chatApi";
import {useChatStore} from "./store/chat-store";
import {subscribePush} from "./api/pushApi";
import {useSubscribe} from "./utils/hooks/useSubscribe";
import {pushPublicKey} from "./utils/consts";


function App() {
  //const height = use100vh()
  const [getPersonalInfo, firstName, isAuthenticated, setIsAuthenticated] = useUserStore((state) => [state.getPersonalInfo, state.firstName, state.isAuthenticated, state.setIsAuthenticated]);
  const [addChatMessage] = useChatStore((state) => [state.addChatMessage]);
  const [isLoading, setIsLoading] = useState(false)

  useLayoutEffect(() => {
    //apiChatGetHistory()
    //apiChatShowMore(2949)
    deviceType()
    //localStorage.removeItem('animationShown')

    getPersonalInfo().then((res) => {
      if (res && res.status === 'success') {
        setIsAuthenticated(true)
      }
    })
      .finally(() => {
        setIsLoading(true)
      })
  }, []);

  const s = useSubscribe({publicKey: pushPublicKey})

  useEffect(() => {
    //document.body.style.cssText = `--vh: ${height}px`
    if ('visualViewport' in window) {
      requestAnimationFrame(vhFix);

      function vhFix() {
        const vh = window.visualViewport.height;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        requestAnimationFrame(vhFix);
      }
    } else {
      document.body.style.minHeight = '100vh'
    }

    ////s.getSubscription().then((f)=>console.log(f))
  }, [])

  return (
    <>
      <LayoutBase>
        {isLoading && (
          <Router>
            <Routes>
              <Route index element={<AuthDispatcher/>}/>
              <Route path='/test' element={<Test/>}/>
              <Route path='/main' element={<PageMain/>}/>
              <Route path='/register' element={<PageRegister/>}/>
              <Route path='/login' element={<PageLogin/>}/>
              <Route path='/login-auth' element={<PageLoginAuth/>}/>
              <Route path='/login-new-password' element={<PageLoginNewPassword/>}/>
              <Route element={<RequiredAuth/>}>
                <Route path='/main-auth' element={firstName ? <PageMainAuth/> : <PageSettings/>}/>
                <Route path='/settings' element={<PageSettings/>}/>
                <Route path='/notifications' element={<PageNotifications/>}/>
                <Route path='/call' element={
                  <SIPProvider
                    options={{
                      domain: "188.120.230.120.sslip.io",
                      webSocketServer: "wss://188.120.230.120.sslip.io:8089/ws",
                    }}
                  >
                    <PageCall/>
                  </SIPProvider>
                }/>
                <Route path='/chat' element={<PageChat/>}/>
              </Route>
            </Routes>
          </Router>
        )}
        {!isLoading && (
          <h1>загрузка</h1>
        )}
        <OfflineMessage/>
      </LayoutBase>

    </>
  );
}

export default App;
